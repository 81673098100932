<template>
  <div id="site_notice">
    <div class="tabbar">
        <img class="trumpet" src="@/assets/image/PersonalCenter/SiteNotice/trumpetUnread.png">
        <div :class="item===selectItem?'isAcitve':''" v-for="(item,index) in tabbarItem" :key="index" @click="clickItem(item,index)">
          {{item}}
        </div>
<!--      <div class="readAll">123</div>-->
       <span class="all_read" @click="readAll">全部已读</span>
    </div>
    <div class="notice_list" v-if="totalCount !== 0">
      <div :class="item.status === 0 ?'notice_list_item_unread':'notice_list_item'" class="notice_wrapper" v-for="(item,index) in tableData" :key="index" @click="checkNotice(item,index)">
        <div class="notice_left"></div>
        <div class="notice_center">
          <div class="not_cen_top">
            <div class="not_cen_title">系统通知</div>
            <div class="not_cen_time">
              {{ item.add_time|moments }}
            </div>
          </div>
          <div class="notice_content">
            <div class="item_content">{{item.content}}</div>
            <div class="item_state">
              <el-tag v-if="item.status ===0 " type="warning" size="medium">未读</el-tag>
              <el-tag v-else type="info" size="medium">已读</el-tag>
            </div>
          </div>
        </div>
        <div class="notice_right">
          <i class="el-icon-caret-right"></i>
        </div>
      </div>
      <div class="pages" v-if="totalCount !== 0">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="pages"
          :page-size="PageSize"
          layout="prev, pager, next, jumper"
          :total="totalCount">
        </el-pagination>
      </div>
      <div></div>
    </div>
    <div class="notice_list no_data" v-else>
      <div id="noData">
        <img src="@/assets/image/PersonalCenter/SiteNotice/noData.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import {apiStationList,apiStationRead,apiValuationDetail,apiReadAll} from "@/request/API";

export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount:0,
      // 默认每页显示的条数（可修改）
      PageSize:10,
      selectItem:'站内通知',
      tabbarItem:['站内通知'],
      tableData: [
        {content:'您的商品：1293949773已经售出！',date: '2016-05-02', state: '未读', priceCut:'untreated'},
        {content:'您的商品：1293949773已经售出！',date: '2016-05-04', state: '未读', priceCut:'agree'},
        {content:'系统通知：网站更新',date: '2016-05-01', state: '未读', priceCut:'refuse'},
        {content:'系统通知：网站更新',date: '2016-05-03', state: '已读', priceCut:'refuse'},
        {content:'系统通知：网站更新',date: '2016-05-03', state: '已读', priceCut:'refuse'},
        {content:'系统通知：网站更新',date: '2016-05-03', state: '已读', priceCut:'refuse'},
        {content:'系统通知：网站更新',date: '2016-05-03', state: '已读', priceCut:'refuse'},
        {content:'系统通知：网站更新',date: '2016-05-03', state: '已读', priceCut:'refuse'},
        {content:'系统通知：网站更新',date: '2016-05-03', state: '已读', priceCut:'refuse'},
        {content:'系统通知：网站更新',date: '2016-05-03', state: '已读', priceCut:'refuse'},
      ],
      //分页页码
      pages:1,
      // 估价通知弹窗
      // ValuationNoticeDialogVisible: false,
      // 估价账号价值金额
      assess_price: '',
      // 估价客服留言
      assess_price_note: '',
      product_id: '',
      game_id: '',
      sell_customer: ''
    }
  },
  methods: {
    clickItem(item, index) {
      this.selectItem = item
    },
    //分页监听事件
    handleCurrentChange(val) {
      this.getStationList(val)
    },
    //查看 站内通知
    checkNotice(item,index) {

      if(item.product_type === 4){
        apiValuationDetail({
          id:item.product_id
        }).then(res => {
          this.product_id = item.product_id
          this.game_id = res.data.game_id
          this.$router.push({
            name:'ValuationInformation',
            query:{
              product_id: this.product_id,
              game_id:this.game_id
            }
          })
          apiStationRead({
            id:item.id
          }).then(res=>{
            this.getStationList(this.pages)
          })
        })
      } else if(item.product_type === 5){
        // let index = item.content.indexOf("。") + 1
        // let content_top = item.content.slice(0,index)
        // let content_a = item.content.slice(index)
        this.$alert(item.content, '消息通知', {
          confirmButtonText: "认证信息",
          confirmButtonClass:'site_confirm_btn',
          customClass: 'site_confirm',
        }).then(() => {
          window.open(item.content_link, "_blank")
          apiStationRead({
            id:item.id
          }).then(res=>{
            this.getStationList(this.pages)
          })
        }).catch(() => {
          apiStationRead({
            id:item.id
          }).then(res=>{
            this.getStationList(this.pages)
          })
        });
      } else if(item.product_type === 6){
        this.$alert(item.content, '消息通知', {
          confirmButtonText: '确认',
          confirmButtonClass:'site_confirm_btn',
          customClass: 'site_confirm',
          callback: action => {
            apiStationRead({
              id:item.id
            }).then(res=>{
              this.getStationList(this.pages)
              this.$router.push({
                name: 'ContractInfo'
              })
            })
          }
        });
      }else{
        this.$alert(item.content, '消息通知', {
          confirmButtonText: '确认',
          confirmButtonClass:'site_confirm_btn',
          customClass: 'site_confirm',
          callback: action => {
            apiStationRead({
              id:item.id
            }).then(res=>{
              this.getStationList(this.pages)
            })
          }
        });
      }
    },
    /**
     * 获取 站内通知 列表
     * @param page 当前页码
     */
    getStationList(page){
      apiStationList({
        page:page,
      }).then(res=>{
        this.totalCount = res.data.total
        res.data.list.map(item => {
          if(item.product_type === 5){
            let index = item.content.indexOf("。") + 1
            let content_top = item.content.slice(0,index)
            let content_a = item.content.slice(index)
            item.content = content_top
            item.content_link = content_a
            return item
          }
          return item
        })
        this.tableData = res.data.list
      })
    },
    /**
     * 一键已读
     */
    readAll(){
      this.pages = 1
      apiReadAll({}).then(res=>{
        this.getStationList('1')
      })
    }
  },
  mounted () {
    //初始化数据
    this.getStationList(1)
  },
  watch: {},
  computed: {

  }
}
</script>
<!-- 弹框 -->
<style lang="less">
.site_confirm{
  width: 386px !important;
  //height: 232px !important;
  background: #FFFFFF !important;
  border-radius: 10px !important;
  padding: 0;
  border: none;
  .el-message-box__header,.el-message-box__content,.el-message-box__btns{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
  .el-message-box__title{
    margin-top: 36px;
    font-size: 20px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #5B5B5B;
    line-height: 28px;
  }
  .el-message-box__message{
    padding: 0 30px;
  }
  .el-message-box__content>.el-message-box__container{
    margin-top: 30px;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 22px;
  }
  .site_confirm_btn{
    margin: 36px 0 ;
    padding: 0;
    width: 200px;
    height: 40px;
    line-height: 40px;
    background: #FFDB51 !important;
    border-radius: 28px;
    border: none;
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #000000 !important;
  }
  .site_confirm_btn:hover{
    background: #FFDB51 !important;
  }
}
</style>
<style lang='less' scoped>
#site_notice{
  // padding: 0 22px;
  display: flex;
  flex-direction: column;
  .tabbar{
    margin: 22px 19px 0;
    height: 58px;
    background: #F4F4F4;
    border-radius: 10px;
    padding: 0 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .trumpet{
      width: 25px;
      height: 20px;
      margin-right: 7px;
    }
    div{
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
      margin-right: 69px;
      cursor: pointer;
    }
    .isAcitve{
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      // padding-bottom: 6px;
      // background: url("../../../assets/image/PersonalCenter/SiteNotice/selectItem.png") no-repeat;
      background-position:bottom;
    }
    div:last-of-type{
      margin: 0;
    }
    .all_read{
      flex: 1;
      margin-right: 20px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #000000;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      &::before{
        content: ' ';
        display: block;
        height: 20px;
        width: 20px;
        background: url("../../../assets/image/PersonalCenter/SiteNotice/allRead.png") no-repeat;
        background-size: contain;
        margin-right: 5px;
      }
      &:hover{
        color: #434ED6;
        &::before{
          background: url("../../../assets/image/PersonalCenter/SiteNotice/allReadHover.png") no-repeat;
          background-size: contain;
        }
      }
    }
  }
  .notice_list{
    display: flex;
    flex-direction: column;
    .notice_wrapper{
      position: relative;
      cursor: pointer;
      &:hover{
        background: #FAFAFF;
        .notice_left,.notice_center{
          background: #FAFAFF;
        }
        .notice_right{
          color: #747DE9;
          opacity: 1;
        }
      }
      .notice_left{
        position: absolute;
        height: 100%;
        width: 19px;
        top: 0;
        left: 0;
      }
      .notice_center{
        margin-left: 19px;
        border-bottom: 1px solid #e2e2e2;
        padding: 20px 50px 20px 19px;
        flex: 1;
        .not_cen_top{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .not_cen_title{
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Semibold;
            font-weight: 600;
            color: #5b5b5b;
            position: relative;
            text-indent: 13px;
            &::before{
              content: ' ';
              position: absolute;
              width: 6px;
              height: 6px;
              top: 7px;
              left: 0;
              background: #5B5B5B;
            }
          }
          .not_cen_time{
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Light;
            font-weight: 300;
            color: #8f8f8f;
          }
        }
        .notice_content{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
          .item_content{
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #5b5b5b;
          }
        }
      }
      .notice_right{
        width: 24px;
        color: #FCAD13;
        font-size: 24px;
        opacity: 0;
      }
    }

    .notice_list_item_unread{
      display: flex;
      flex-direction: row;
      align-items: center;
      .item_state{
        margin-left: 110px;
        /deep/.el-tag--warning{
          background: #EEEEFF !important;
          border-radius: 5px;
          font-size: 16px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          color: #747DE9 !important;
          border: none;
        }
      }
      .item_btn{
        width: 100px;
        height: 30px;
        line-height: 30px;
        background: #FFDB51;
        border-radius: 20px;
        font-size: 16px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #000000;
        text-align: center;
        cursor: pointer;
      }
    }
    .notice_list_item{
      display: flex;
      flex-direction: row;
      align-items: center;
      // height: 62px;
      // padding: 0 15px 0 45px;
      // border-bottom: 1px solid #E2E2E2;
      // .item_content{
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      //   white-space: nowrap;
      //   font-size: 16px;
      //   font-family: PingFangSC-Thin, PingFang SC;
      //   font-weight: 300;
      //   color: #B4B4B4;
      //   line-height: 22px;
      //   margin:  0 0 0 11px;
      //   width: 292px;
      // }
      .item_state{
        // margin-right: 40px;
        margin-left: 110px;
        /deep/.el-tag--info{
          background: #F0F0F0;
          border-radius: 5px;
          font-size: 16px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          color: #BFBFBF;
          border: none;
        }
      }
      .item_btn{
        width: 100px;
        height: 30px;
        line-height: 30px;
        background: #F0F0F0;
        border-radius: 20px;
        font-size: 16px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #A7A7A7;
        text-align: center;
      }
    }
    .pages{
      height: 94px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .no_data{
    height: 696px;
    display: flex;
    justify-content: center;
    align-items: center;
    #noData{
     img{
       height: 100%;
       width: 100%;
     }
    }
  }
}
</style>
